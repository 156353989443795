/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Link, useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import CustomerChat from "./CustomerChat"
import Book from "./book"
import "./layout.css"
import "typeface-roboto"
import logo from "../images/syndic-en-un-clic-logo_white.svg"
import frenchTech from "../images/Logo_French_Tech.svg"

const Layout = ({ children,color }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} color={color} />
        <CustomerChat/>
        <main>{children}</main>
        <Book></Book>
        <footer>
          <div className="footer">
            <div>
              <h3>Menu</h3>
              <ul> 
                <li><Link to="/">Accueil</Link></li>
                <li><Link to="/gestionnaires/">Gestionnaires</Link></li>
                <li><Link to="/residents/">Résidents</Link></li>
                <li><Link to="/fonctionnalites/">Fonctionnalités</Link></li>
                <li><Link to="/qui-sommes-nous/">Qui sommes-nous ?</Link></li>
                <li><Link to="/foire-aux-questions/">Foire aux questions (FAQ)</Link></li> 
              </ul>
            </div>
            <img height={150} src={frenchTech} className='frenchtech' alt="French tech" />
          </div>
          <div className="strip">
              <a href="mailto:aurelie@syndic-en-un-clic.fr">aurelie@syndic-en-un-clic.fr</a>
              <b>06 38 59 15 58</b>
            <Link to="/">
              <img height={33} className='logo' src={logo} alt="Logo" />
            </Link>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
