import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logoPrimary from "../images/syndic-en-un-clic-logo.svg"
import logoWhite from "../images/syndic-en-un-clic-logo_white.svg"

const Header = ({ siteTitle,color }) => {
  let backgroundColor = '#DBE6EC'
  let logo = logoPrimary
  if(color=='secondary'){
    logo = logoWhite
    backgroundColor = '#D96A1A'
  }
  if(color=='primary'){
    logo = logoWhite
    backgroundColor = '#546E7A'
  }

  return (
  <header
    style={{
      background: backgroundColor,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      
      <h1 style={{ margin: 0, textAlign:'center' }}>
        <Link to="/">
          <img className='logo' src={logo} alt="Logo" />
        </Link>
      </h1>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
