import { Link } from "gatsby"
import React from "react"
import styles from "./book.module.css"

import livreBlanc from "../images/econhomes-livre-blanc-charges.png"


const book = () => (
  <div className={styles.container}>
    <h2>TELECHARGER<br/><span className={styles.secondary}>GRATUITEMENT</span><br/>LE LIVRE BLANC</h2>
    <h3>sur les charges de copropriété</h3>
    <a target='_blank' title='Télécharger le livre blanc tout comprendre sur les charges' href="https://econhomes.fr/syndic-en-un-clic-guide-tout-comprendre-sur-les-charges/">
      <img width="400" heigth="400" src={livreBlanc}/>
    </a>
    <div className={styles.btn_container}>
      <a target='_blank' className={styles.btn} href="https://econhomes.fr/syndic-en-un-clic-guide-tout-comprendre-sur-les-charges/">Télécharger le guide</a>
    </div>
  </div>
)

export default book




